import React, { useState } from 'react';
import Layout from '../components/organisms/layout/layout';
import useMobileMode from '../hooks/useMobileMode';
import CalculatorContent from '../components/organisms/calculator-content/calculator-content';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../shared/components/seo';

const calculator = props => {
  const resultQuery = useStaticQuery(
    graphql`
      query CALCULATOR_QUERY {
        allContentfulPagina(
          filter: { slug: { eq: "calculadora-papel-higienico/" } }
        ) {
          nodes {
            slug
            metaTitle
            metaDescription {
              metaDescription
            }
            node_locale
            description {
              json
            }
            description3 {
              json
            }
            description4 {
              json
            }
            description5 {
              json
            }
            description6 {
              json
            }
            productos {
              id
              contentful_id
              image {
                description
                file {
                  url
                }
              }
              productName {
                productName
              }
              cantidadDeUsos
              commercialPartner
              secondCommercialPartner
              thirdCommercialPartner
            }
            checksLegales {
              idCategoria
              descripcionTextoEnriquecido {
                json
              }
            }
          }
        }
      }
    `
  );

  const contentInfo = resultQuery.allContentfulPagina.nodes.filter(
    e => e.node_locale === process.env.LOCALE
  )[0];

  const menuActive = 2;
  const { isMobile } = useMobileMode();
  const [modalActive, setmodalActive] = useState(null);

  const classes = {
    main: 'f-main',
  };

  return (
    <Layout
      menuActive={menuActive}
      footerActive={false}
      classes={classes}
      setmodalActive={setmodalActive}
      modalActive={modalActive}
      title={contentInfo.metaTitle}
      slug={contentInfo.slug}
    >
      <SEO
        title={contentInfo.metaTitle || ''}
        description={contentInfo.metaDescription?.metaDescription || ''}
      />
      <CalculatorContent
        setmodalActive={setmodalActive}
        contentInfo={contentInfo}
      />
    </Layout>
  );
};

export default calculator;

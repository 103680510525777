import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import './data-policy.scss';

const DataPolicy = () => {
  const result = useStaticQuery(
    graphql`
      query DATA_POLICY {
        dataPolicy: allContentfulHome {
          nodes {
            politicaDeDatos {
              json
            }
          }
        }
      }
    `
  );

  const politicaDeDatos = result.dataPolicy.nodes.filter(
    e => e.politicaDeDatos !== null
  )[0]?.politicaDeDatos;

  return (
    <div className="f-data-policy">
      {documentToReactComponents(politicaDeDatos.json)}
    </div>
  );
};

export default DataPolicy;

import React from 'react'
import './button-calculator.scss'

const ButtonCalculator = props => {
    const { title, classes, disable = false, icon = null, onclick, bottomLabel = null } = props;
    return (
        <div className={"f-button-calculator-container " + (disable ? 'f-disable-button' : '')} onClick={() => onclick()}>
            <div className="f-icon-container">
                {icon}
            </div>
            <button className={"f-button-calculator " + classes + (icon ? ' f-have-icon' : '')}>
                {title}
            </button>
            <span className="f-bottom-button-label">{bottomLabel}</span>
        </div>
    )
}

export default ButtonCalculator

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import mainIMG from '../../../assets/images/calculator-img-L.png';
import useMobileMode from '../../../hooks/useMobileMode';
import CalculatorService from '../../../services/calculator-service';
import DataPolicy from '../../../shared/components/atoms/data-policy/data-policy';
import { siteUrl } from '../../../shared/utils/siteUrl';
import ButtonCalculator from '../../atoms/button-calculator/button-calculator';
import ShopModal from '../../atoms/shop-modal/shop-modal';
import './calculator-content.scss';

const CalculatorContent = props => {
    const { setmodalActive } = props;

    const {
      description,
      description3,
      description4,
      description5,
      description6,
      productos,
      checksLegales,
    } = props.contentInfo;

    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

    const countSteps = 4;
    const [productSelected, setProductSelected] = useState('')
    const { isMobile } = useMobileMode();

    const [stepCalculator, setstepCalculator] = useState(0)
    const [tyc, setTyc] = useState({})

    const [selectedProduct, setselectedProduct] = useState(null)
    const [disabledButton, setdisabledButton] = useState(true)

    const [inputCountPaper, setinputCountPaper] = useState('')
    const [inputCountFamily, setinputCountFamily] = useState('')
    const [inputCountTimes, setinputCountTimes] = useState('')
    const [inputEmail, setinputEmail] = useState('')

    const [flagDisableFamily, setflagDisableFamily] = useState(true)
    const [flagDisableTimes, setflagDisableTimes] = useState(true)

    const [resultUsesAvailable, setresultUsesAvailable] = useState(0)
    const [resultRemainingDay, setresultRemainingDay] = useState(0)

    const [validatedMail, setvalidatedMail] = useState(false)
    const [activeCheckbox, setactiveCheckbox] = useState(false)
    const [errorAlertService, setErrorAlertService]= useState()

    const progressBar = <div className="f-progress-bar-container">
        <div className="f-progress-indicator" style={{ width: stepCalculator * 100 / countSteps + '%' }} />
    </div>

    const handleTyc = (nombreCategoria) => setTyc(current => ({...current, [nombreCategoria]: !current[nombreCategoria]}))

    const validateTems = () => {
        let isChecksOk = checksLegales?.reduce((termsError, {idCategoria}) => {
            if (!tyc[idCategoria] || tyc[idCategoria] === false) {
              termsError = false 
            }
            return termsError
          }, true)
        setactiveCheckbox(isChecksOk)
    }

    const progressLayout = (button, step) => (
        <div className="f-step-layout-container">
            <div className="f-step-info-container">
                {step}
            </div>
            {button}
            {progressBar}
        </div>
    )

    const selectedItemProgressLayout = (button, inputStep) => progressLayout(button,
        <div className="f-selected-item-container">
            {selectedProduct &&
                <>
                    <div className="f-selected-item-container">
                        <img
                            className="f-selected-img"
                            alt={selectedProduct.image[0].description}
                            src={selectedProduct.image[0].file.url}
                        />
                        <div className="f-title-selected-item-container">
                            <div className="f-selected-item-title">Papel Higiénico</div>
                            <span className="f-bold-title">{selectedProduct.productName.productName}</span>
                        </div>
                    </div>
                    {inputStep}
                </>
            }
        </div>
    )

    var options = {
        dots: false,
        infinite: false,
        // speed: 500,
        slidesToShow: isMobile ? 1 : 6,
        slidesToScroll: 1,
        arrows: true,
        // beforeChange: (current, next) => setCurrentSlide(next + 1),
    };

    const finalCalculate = () => {
        let countPaper = parseInt(inputCountPaper);
        let countFamily = parseInt(inputCountFamily);
        let countTimes = parseInt(inputCountTimes);
        let usesProduct = selectedProduct.cantidadDeUsos;

        let usesAvailable = usesProduct * countPaper;
        let remainingDay =
          usesAvailable / (countTimes * (countFamily !== 0 ? (countFamily + 1) : 1));

        setresultUsesAvailable(usesAvailable)
        setresultRemainingDay(parseInt(remainingDay))
    }

    const handleSelectProduct = info => {
        setselectedProduct(info)
        setdisabledButton(false)
        setProductSelected(info.productName.productName.replace('Familia® ', ''))
    }

    const inputCountPaperHasError = (value) => {
        return value === '' ||
            value === '00' ||
            value === '0'
    }

    const handleTransformInput = value => {
        return (value.replace(/\D/, "").slice(0, 2))
    }

    const handleInputCount = value => {
        setinputCountPaper(handleTransformInput(value))

        inputCountPaperHasError(handleTransformInput(value))
          ? setdisabledButton(true)
          : setdisabledButton(false);
        
    }

    const handleFamilyTimesCount = (family, times) => {
        if (family !== null) {
            setinputCountFamily(handleTransformInput(family));

            (inputCountPaperHasError(handleTransformInput(family)) &&
            handleTransformInput(family) !== '0')
              ? setflagDisableFamily(true)
              : setflagDisableFamily(false);
        }

        if (times !== null) {
            setinputCountTimes(handleTransformInput(times))

            inputCountPaperHasError(handleTransformInput(times))
              ? setflagDisableTimes(true)
              : setflagDisableTimes(false);

        }
    }

    const formatNumber = (num) => {
        if (num > 999999) {
            return parseInt(num / 1000000, 10).toString() + 'M'
        } else if (num > 999) {
            return parseInt(num / 1000, 10).toString() + 'K'
        } else {
            return num
        }
    }

    const handleValidateEmail = val => {
        setinputEmail(val)

        emailRegex.test(val) ? setvalidatedMail(true) : setvalidatedMail(false);
        
    }

    const postInfoToDB = () => {
        let payload = {
            email: inputEmail,
            productId: selectedProduct.contentful_id,
            countryId: process.env.COUNTRY_CODE,
            rollsQuantity: inputCountPaper,
            peopleQuantity: inputCountFamily,
            useForDay: inputCountTimes,
            numberOfUsesProduct: selectedProduct.cantidadDeUsos
        }
        setErrorAlertService(null)

        let legalPayload = {}

        checksLegales?.forEach(({ idCategoria }) => {
          legalPayload[idCategoria] = tyc[idCategoria]
        })

        CalculatorService.postCalculatorUserInfo({...payload, ...legalPayload}).then(res => {
            if(res.status === 200){
                setstepCalculator('success')
            }else if(res.status === 400){
                setErrorAlertService(res.userMessage)
            }
        })
    }

    useEffect(() => {
        validateTems()
    }, [tyc])

    const step0 = <div className="f-step0-container">
        <div className="f-description-container">
            {documentToReactComponents(description.json)}
        </div>
        <div className="f-button-container">
            <ButtonCalculator
                title='Empezar'
                onclick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event':'Interacciones - FamiCalculadora',
                        'category':'Famicalculadora',
                        'action':'Click',
                        'label':'Empezar'
                        });
                        setstepCalculator(1)
                    }
                }
                classes={'f-l-button'}
            />
        </div>
    </div>

    const step1 = progressLayout(
        <div className="f-button-container">
            <ButtonCalculator
                title='Siguiente'
                onclick={() => { 
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                    'event':'Interacciones - FamiCalculadora',
                    'category':'Famicalculadora',
                    'action':'Clic-producto',
                    'label': productSelected,
                    'item_name':'Delux, eXpert, Green, Megarrollo, etc.',
                });
                    setstepCalculator(stepCalculator + 1); 
                    setdisabledButton(true); 
                    }
                }
                classes={'f-m-button'}
                disable={disabledButton && (flagDisableFamily || flagDisableTimes)}
            />
        </div>
        ,
        <div className="f-step1-container">
            <div className="f-step1-title">¿Cuál es el <span className="f-bold-title">Papel Higiénico Familia®</span> que usas en casa?</div>
            <div className="f-list-products-container">
                {isMobile ?
                    <Slider {...options}>
                        {/* first 6 products */}
                        <div>
                            <div className="f-slider-mobile">
                                {productos.map((product, index) => {
                                    if (index < 6) {
                                        return (
                                            <div key={index} className={"f-product-mini-card " + ((selectedProduct && selectedProduct.contentful_id) === product.contentful_id ? 'f-active-card' : '')} onClick={() => handleSelectProduct(product)}>
                                                <div className="f-product-img-container">
                                                    <img
                                                        className="f-product-img"
                                                        alt={product.image[0].description}
                                                        src={product.image[0].file.url}
                                                    />
                                                </div>
                                                <div className="f-product-short-name">
                                                    {product.productName.productName.replace('Familia® ', '')}
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        null
                                    }
                                })}
                            </div>
                        </div>
                        {/* next 6 products */}
                        {productos.lenght >= 6 && <div>
                            <div className="f-slider-mobile">
                                {productos.map((product, index) => {
                                    if (index >= 6) {
                                        return (
                                            <div key={index} className={"f-product-mini-card " + ((selectedProduct && selectedProduct.contentful_id) === product.contentful_id ? 'f-active-card' : '')} onClick={() => handleSelectProduct(product)}>
                                                <div className="f-product-img-container">
                                                    <img
                                                        className="f-product-img"
                                                        alt={product.image[0].description}
                                                        src={product.image[0].file.url}
                                                    />
                                                </div>
                                                <div className="f-product-short-name">
                                                    {product.productName.productName.replace('Familia® ', '')}
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        null
                                    }
                                })}
                            </div>
                        </div>}

                    </Slider>
                    : <Slider {...options}>
                        {productos.map((product, index) => {
                            return (
                                <div key={index} >
                                    <div className={"f-product-mini-card " + ((selectedProduct && selectedProduct.contentful_id) === product.contentful_id ? 'f-active-card' : '')} onClick={() => handleSelectProduct(product)}>
                                        <div className="f-product-img-container">
                                            <img
                                                className="f-product-img"
                                                alt={product.image[0].description}
                                                src={product.image[0].file.url}
                                            />
                                        </div>
                                        <div className="f-product-short-name">
                                            {product.productName.productName.replace('Familia® ', '')}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                }
            </div>
        </div>
    )

    const step2 = selectedItemProgressLayout(
        <div className="f-button-container">
            <ButtonCalculator
                title='Siguiente'
                onclick={() => { 
                    window.dataLayer = window.dataLayer || []; 
                    window.dataLayer.push({
                    'event':'Interacciones - FamiCalculadora',
                    'category':'Famicalculadora',
                    'action':'Click',
                    'label':'Siguiente',
                    'item_name':'Delux, eXpert, Green, Megarrollo, etc.',
                    'numero_rollos': inputCountPaper
                });  
                    setstepCalculator(stepCalculator + 1); 
                    setdisabledButton(true); 
                }}
                classes={'f-m-button'}
                disable={disabledButton && (flagDisableFamily || flagDisableTimes)}
            />
        </div>
        ,
        <div className="f-input-group-step2-container">
            <span className="f-input-label">{documentToReactComponents(description3.json)}</span>
            <div className="f-input-container">
                <input
                    className="f-input-calculator"
                    type='number'
                    value={inputCountPaper}
                    onChange={e => handleInputCount(e.target.value)}
                ></input>
            </div>
        </div>
    )

    const step3 = selectedItemProgressLayout(
        <div className="f-button-container">
            <ButtonCalculator
                title='Siguiente'
                onclick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                    'event':'Interacciones - FamiCalculadora',
                    'category':'Famicalculadora',
                    'action':'Click',
                    'label':'Siguiente',
                    'item_name':'Delux, eXpert, Green, Megarrollo, etc.' 
                    });
                    setstepCalculator(stepCalculator + 1); 
                    setdisabledButton(true); 
                    finalCalculate(); 
                }}
                classes={'f-m-button'}
                disable={disabledButton && (flagDisableFamily || flagDisableTimes)}
            />
        </div>
        ,
        <div className="f-input-group-step3-container">
            <div className="f-icon-label-container f-icon-label-container-top">
                <span className="f-icon-container f-icon-family" />
                <span className="f-input-label">{documentToReactComponents(description4.json)}</span>
            </div>
            <div className="f-input-container">
                <input
                    className="f-input-calculator"
                    type='number'
                    value={inputCountFamily}
                    onChange={e => handleFamilyTimesCount(e.target.value, null)}
                ></input>
            </div>
            <div className="f-icon-label-container">
                <span className="f-icon-container f-icon-paper" />
                <span className="f-input-label">{documentToReactComponents(description5.json)}</span>
            </div>
            <div className="f-input-container">
                <input
                    className="f-input-calculator"
                    type='number'
                    value={inputCountTimes}
                    onChange={e => handleFamilyTimesCount(null, e.target.value)}
                ></input>
            </div>
        </div>
    )

    const step4 = selectedItemProgressLayout(
      <div className="f-button-container">
        {selectedProduct && (
          <>
            <ButtonCalculator
              title="Alarma Familia®"
              onclick={() =>{ 
                window.dataLayer = window.dataLayer || []; 
                window.dataLayer.push({
                'event':'Interacciones - FamiCalculadora',
                'category':'Famicalculadora',
                'action':'Click',
                'label':'Alarma Familia',
                'item_name':'Delux, eXpert, Green, Megarrollo, etc.',
                });
                  setstepCalculator('alert')
                }
              }
              classes={'f-s-button'}
              icon={<i className="fal fa-bell f-icon-button"></i>}
              bottomLabel={documentToReactComponents(description6.json)}
            />
            <ButtonCalculator
              title="¡Comprar!"
              onclick={() =>{
                window.dataLayer = window.dataLayer || []; 
                window.dataLayer.push({
                'event':'Interacciones - FamiCalculadora',
                'category':'Famicalculadora',
                'action':'Click',
                'label':'Comprar',
                'item_name':'Delux, eXpert, Green, Megarrollo, etc.',
                });
                setmodalActive(
                  <ShopModal
                    commercialPartners={[
                      selectedProduct.commercialPartner,
                      selectedProduct.secondCommercialPartner,
                      selectedProduct.thirdCommercialPartner,
                    ]}
                    setmodalActive={setmodalActive}
                  />
                )
                }
              }
              classes={'f-s-button f-margin-left'}
              icon={<i className="fal fa-shopping-cart f-icon-button"></i>}
            />
          </>
        )}
      </div>,
      <div className="f-result-step">
        <div className="f-info-result-container">
          <div className="f-info-result-title">Tienes  {formatNumber(resultUsesAvailable)} cuadritos que
             te alcanzan para {formatNumber(resultRemainingDay)} días de visitas al gran trono</div>
          <div className="f-info-result-value">
            {formatNumber(resultUsesAvailable)}
          </div>
        </div>
        <div className="f-info-result-container">
          {/* <div className="f-info-result-title">
            visitas al
            gran trono y te duran para
          </div> */}
          <div className="f-info-result-value">
            {formatNumber(resultRemainingDay)}
            <span className="f-days-container">días</span>
          </div>
        </div>
      </div>
    );

    const stepAlert = <div className="f-step-alert">
        <div className="f-info-container">
            <p className="f-p-step-alert">
                Ingresa tu e-mail y te avisaremos <b>5 días antes</b> de que se termine tu <b>Papel Higiénico Familia</b>®
            </p>
            <input
                className="f-input-calculator"
                type='text'
                // value={inputCountFamily}
                onChange={e => {
                    setErrorAlertService(null)
                    handleValidateEmail(e.target.value)
                }}
            ></input>
            {
                errorAlertService && <span className="f-input-calculator__error"> {errorAlertService} </span>
            }

            {checksLegales?.map(({idCategoria, descripcionTextoEnriquecido}, index)=>{ 
                return <div className="f-custom-checkbox-container" key={index}>
                        <span className={"f-custom-checkbox-square " + (tyc[idCategoria] ? 'f-selected' : '')} 
                            onClick={() => {
                                handleTyc(idCategoria)
                            }}
                        >
                        <i className="far fa-check"></i></span>
                        <label className="f-checkbox-redirect">
                            {descripcionTextoEnriquecido && documentToReactComponents(descripcionTextoEnriquecido.json)}
                        </label>
                        {/* <a className='f-checkbox-redirect' href='/terminos-y-condiciones/' target="_blank">Acepto términos y condiciones</a> */}
                    </div>
            })}

            <DataPolicy />

            <div className="f-button-container">
                <ButtonCalculator
                    title='Enviar'
                    onclick={() => {
                        window.dataLayer = window.dataLayer || []; 
                        window.dataLayer.push({
                            'event':'Interacciones - FamiCalculadora',
                            'category':'Famicalculadora',
                            'action':'Click',
                            'label':'Enviar',
                            'e-mail':'Vi3kxGApQhWJw5I2WFWaT8cyn8KE6x21uxfuaPUqGA' 
                            });
                        postInfoToDB()
                        }
                    }
                    classes={'f-m-button f-large-button'}
                    disable={!activeCheckbox || !validatedMail}
                />
            </div>
        </div>
    </div>

    const stepSuccess = <div className="f-step-success">
        <div className="f-info-container">
            <p className='f-bold-title f-first-title'>Gracias</p>
            <p className='f-succes-description'>por usar la <span className="f-bold-title">FamiCalculadora</span></p>
            <p className='f-succes-description'>te avisaremos cuando te estés quedando sin <span className="f-bold-title">papel higiénico.</span></p>
        
            <div className="f-button-container">
                <ButtonCalculator
                    title='Volver'
                    onclick={() => window.location.href = siteUrl}
                    classes={'f-l-button f-large-button'}
                    disable={false}
                />
            </div>
        </div>
    </div>

    const returnStep = (step) => {
        switch (step) {
            case 0:
                return step0
            case 1:
                return step1
            case 2:
                return step2
            case 3:
                return step3
            case 4:
                return step4
            case 'alert':
                return stepAlert
            case 'success':
                return stepSuccess
            default:
                return step0
        }
    }

    return (
        <div className="f-calculator-background">
            <div className="f-calculator-modal-container">
                <div className={"f-calculator-main-img-container " + (stepCalculator === 0 ? 'f-calculator-home-img' : '')}>
                    <img
                        src={mainIMG}
                        alt='Imagen principal calculadora'
                        className="f-calculator-main-img"
                    />
                </div>
                <div className="f-info-modal-container">
                    {returnStep(stepCalculator)}
                </div>
            </div>
        </div>
    )
}

export default CalculatorContent
